/**
 * Editorial
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Editorial
 * @param {object} props - Props
 */

import React from "react";
// import Parser from 'html-react-parser';

const Editorial = ({children}) => {

	return (
		<section className="editorial-about-us">
		   <div className={'container'}>
			{children}
		   </div>
		</section>
	);
}

export default Editorial;

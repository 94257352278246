/**
 * Dynamic
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class Dynamic
 * @param {object} props - Props
 */

import React from "react";
import CardArticle from "../../components/card-article";
import EditorialIntro from "../../components/editorial"
import Parser from 'html-react-parser'
import Button from "../../atoms/button";


const Dynamic = ({ items: data, layout = '', ...props }) => {
    const { introTitle, introText, introLabel, introLink } = props;

    return (
        <div className="container">
            {
                introTitle || introText ?
                    <EditorialIntro>
                        <div className="editorial-intro">
                            {introTitle ? <h2>{introTitle}</h2> : null}
                            <div className="p">{introText ? Parser(introText) : null}</div>
                            {introLabel && <Button label={introLabel} className="primary" color="blue" link={introLink} />}
                        </div>
                    </EditorialIntro>

                    : null
            }
            <div className={`dynamic-container dynamic-container--${data.length}-child`}>
                {data.map(({ children = [], ...other }, idx) => (
                    <CardArticle
                        key={idx}
                        dynamic={true}
                        items={children}
                        layout={layout}

                        {...{ ...props, ...other }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Dynamic;


/**
 * HeroModule
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class HeroModule
 * @param {object} props - Props
 */

import React from "react";
import Button from "../../atoms/button";
import VideoButtonOverlay from "../../components/video-button-overlay";
import Parser from 'html-react-parser';

const HeroModule = ({title, description, link, image, video, buttonLabel}) => {

    return (
        <div className="container hero-module">
            <div className="hero-module__body">
                <div className="hero-module__title">{title}</div>
                <div className="hero-module__description">{Parser(description)}</div>
            </div>
            <div className="hero-module__image-container">
                {video && <VideoButtonOverlay videoUrl={link}/>}
                <div className="hero-module__image" style={{backgroundImage: `url(${image})`}}>&nbsp;</div>
            </div>
            {buttonLabel && <Button link={link} label={buttonLabel} color="blue" className="primary" />}
        </div>
    );
};

export default HeroModule;


/**
 * ListingItem
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class ListingItem
 * @param {object} props - Props
 */

import React from "react";
import Icon from "../../atoms/icon";
import Button from "../../atoms/button";
import Parser from 'html-react-parser';

class ListingItem extends React.Component {

	render() {
		const { icon, iconColor, iconUrl, iconHeight, iconWidth, imageUrl, title, text, cta } = this.props;
		return (
			<div className="listing__item">
				{
					icon ?
						<Icon
							color={iconColor}
							name={icon}
							url={iconUrl}
							height={iconHeight}
							width={iconWidth}
						/>
						: null
				}
				{
					imageUrl ?
						<div className="listing__image" style={{
							backgroundImage: `url(${imageUrl})`
						}}></div>
						: null
				}

				<div className="listing__title">
					{title}
				</div>
				<div className="listing__text">
					{Parser(text)}
				</div>
				{
					cta ?
						<div className="listing__cta">
							
							<Button
								className="secondary"
								color="blue"
								href={cta.link}
								label={cta.label}
							/>
						</div>
						: null
				}
			</div>
		);
	}

}

export default ListingItem;

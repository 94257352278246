import React from "react";
import VideoButtonOverlay from "../video-button-overlay";
import Button from "../../atoms/button";
import CardSwiper from "../card-swiper";
import Icon from "../../atoms/icon";
import Parser from 'html-react-parser';

const TripletContent = ({ title, description, imagesSize = '', items = [], direction = 'right', buttonLabel, link, icon, shadow, ...others }) => {
    return (
        <>
            <div className={`triplet-content triplet-content--${direction}-aligned ${items.length === 0 ? 'triplet-content--with-icon' : ''} ${imagesSize}`}>
                <div className="triplet-content__body">
                    <div className="triplet-content__title">{title}</div>
                    <div className="triplet-content__description">
                        {Parser(description)}
                    </div>
                    {buttonLabel && <Button label={buttonLabel} className="primary" color="blue" link={link} />}
                </div>
                <div className="triplet-content__image-container">
                    {(items.length === 1 && items[0]?.video) && <VideoButtonOverlay videoUrl={items[0].link} />}
                    {items.length === 1 && <div className={`triplet-content__image ${items[0]?.image ? '' : 'triplet-content__image--no-image'} ${shadow === false || items[0]?.image === undefined ? `triplet-content__image--no-shadow` : ''}`}
                        style={{
                            backgroundImage: `url(${items[0].image})`
                        }}>&nbsp;</div>}
                    {items.length > 1 && <CardSwiper fade={true} items={items} />}
                    {items.length === 0 && <Icon name={icon} color="blue" {...others} />}
                </div>
            </div>
        </>
    );
};

export default TripletContent;


/**
 * ImagesList
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class ImagesList
 * @param {object} props - Props
 */

import React from "react";
import EditorialIntro from "../../components/editorial";
import Parser from "html-react-parser";
import Button from "../../atoms/button";
import { Link } from "../../atoms/link";

const ImagesList = (
    {
        introTitle,
        introText,
        introLabel,
        introLink,
        images = [],
        itemsPerRow = 4
    }
) => {

    return (
        <div className="images-container">
            {
                introTitle || introText ?
                    <EditorialIntro>
                        <div className="editorial-intro">
                            {introTitle ? <h2>{introTitle}</h2> : null}
                            <div className="p">{introText ? Parser(introText) : null}</div>
                            {introLabel &&
                                <Button label={introLabel} className="primary" color="blue" link={introLink} />}
                        </div>
                    </EditorialIntro>

                    : null
            }
            <div className={`images-container__list ${itemsPerRow === 5 ? 'images-container__list--5-per-row' : ''}`}>
                {images.length > 1 && images.map((image, idx) => (
                    <Link to={image.link}>
                        <img src={image.image} alt={image.label} />
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default ImagesList;

/**
 * ProductCardAttributes
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class ProductCardAttributes
 * @param {object} props - Props
 */

import React from "react";
import Parser from "html-react-parser";

const ProductCardAttributes = (product) => {


	const { attributes } = product;

	return (
		<div className={"product-card__attributes"}>
			{attributes.map((attribute, idx) => (
				<div key={idx} className={'product-card__attribute'}>
					<div className={'fieldValue'}>
						{attribute.fieldValue ? <small className="fieldName">{attribute.fieldName}</small> : null}
						{attribute.fieldValue ? attribute.fieldValue : Parser('&nbsp;')}
					</div>
				</div>
			))}
		</div>
	);
}

export default ProductCardAttributes;

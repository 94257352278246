/**
 * Triplet
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class Triplet
 * @param {object} props - Props
 */

import React from "react";
// import Slider from '../../components/slider';
import 'swiper/swiper-bundle.css';
import TripletContent from '../../components/tripletContent';

import EditorialIntro from "../../components/editorial"
import Parser from 'html-react-parser'
import Button from "../../atoms/button";

const Triplet = ({ items, startRightAligned = true, ...props }) => {
    const { introTitle, introText, introLabel, introLink } = props;
    return (
        <section className='container triplets-container'>
            {
                introTitle || introText ?
                    <EditorialIntro>
                        <div className="editorial-intro">
                            {introTitle ? <h2>{introTitle}</h2> : null}
                            <div className="p">{introText ? Parser(introText) : null}</div>
                            {introLabel && <Button label={introLabel} className="primary" color="blue" link={introLink} />}
                        </div>
                    </EditorialIntro>

                    : null
            }
            {items.map((item, idx) => {
                const odd = idx % 2 === 0;
                const direction = !odd ? startRightAligned : !startRightAligned;
                return (
                    <TripletContent key={idx} direction={direction ? 'left' : 'right'} {...item} />
                )
            })}
        </section>
    );
};


export default Triplet;


/**
 * Listing
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class Listing
 * @param {object} props - Props
 */

import React, { Fragment } from "react";
import ListItem from "../../components/listing-item"
import EditorialIntro from "../../components/editorial"
import Parser from 'html-react-parser'
import Button from "../../atoms/button";

class Listing extends React.Component {

	render() {
		const { type, items } = this.props
		const { introTitle, introText, introLabel, introLink } = this.props
		return (
			<Fragment>
				<div className="container">
					{
						introTitle || introText ?
							<EditorialIntro>
								<div className="editorial-intro">
									{introTitle ? <h2>{introTitle}</h2> : null}
									<div className="p">{introText ? Parser(introText) : null}</div>
									{introLabel && <Button label={introLabel} className="primary" color="blue" link={introLink} />}
								</div>
							</EditorialIntro>

							: null
					}
					<div className={`listing listing--${type} listing--${items.length}items`}>
						{
							items.map((item, idx) => (
								<ListItem
									imageUrl={item.imageUrl}
									icon={item.icon}
									iconHeight={item.iconHeight}
									iconWidth={item.iconWidth}
									iconUrl={item.iconUrl}
									title={item.title}
									text={item.text}
									cta={item.cta}
									key={idx}
								/>
							))
						}

					</div>
				</div>
			</Fragment>
		);
	}

}

export default Listing;


/**
 * GalleryItem
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class GalleryItem
 * @param {object} props - Props
 */

import React from "react";
import VideoButtonOverlay from "../video-button-overlay";
import Parser from 'html-react-parser'
import Button from "../../atoms/button";

const GalleryItem = ({ title, description, image, video, withoutCaption, link, buttonLabel = '', buttonOptions= {}}) => (
    <div className={`gallery-item-container ${withoutCaption && 'gallery-item-container--without-caption'}`}>
        <div className="gallery-item-container__image-wrapper">
            {video && <VideoButtonOverlay videoUrl={link} />}
            <div className="gallery-item-container__image" style={{ backgroundImage: `url(${image})` }}>&nbsp;</div>
        </div>
        <div className="gallery-item-container__body">
            <p className="gallery-item-container__title">{title}</p>
            <div className="gallery-item-container__description">
                {description && Parser(String(description))}
            </div>
            {buttonLabel.length > 0 && (
                <Button
                    label={buttonLabel}
                    {...buttonOptions}
                />
            )}
        </div>
    </div>
);

export default GalleryItem;

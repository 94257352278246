/**
 * Gallery
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class Gallery
 * @param {object} props - Props
 */

import React from "react";
import SwiperCore, { Pagination, A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import GalleryItem from "../../components/gallery-item";

import EditorialIntro from "../../components/editorial"
import Parser from 'html-react-parser'
import Button from "../../atoms/button";

SwiperCore.use([Pagination, A11y, Navigation]);
const Gallery = ({ items = [], withoutCaption = false, ...props }) => {
	const defaultBreakpoints = {
		320: {
			spaceBetween: 10
		},
		768: {
			spaceBetween: 30
		}
	};

	const { introTitle, introText, introLabel, introLink } = props

	return (
		<div className={`container gallery-container ${withoutCaption && 'gallery-container--without-caption'}`}>
			{
				introTitle || introText ?
					<EditorialIntro>
						<div className="editorial-intro">
							{introTitle ? <h2>{introTitle}</h2> : null}
							<div className="p">{introText ? Parser(introText) : null}</div>
							{introLabel && <Button label={introLabel} className="primary" color="blue" link={introLink} />}
						</div>
					</EditorialIntro>

					: null
			}
			<Swiper
				slidesPerView={'auto'}
				spaceBetween={10}
				breakpoints={{ defaultBreakpoints, ...props?.breakpoints }}

				// onSwiper={(swiper) => console.log(swiper)}
				// onSlideChange={() => console.log('slide change')}
				pagination={{ clickable: true }}
				navigation

				centeredSlides={false}
			>
				{items.map((item, idx) => (
					<SwiperSlide key={idx}>
						{/*<CardArticle {...item} />*/}
						<GalleryItem key={idx} {...{ ...item, withoutCaption }} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

export default Gallery;


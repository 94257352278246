/**
 * CompareMegacontent
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class CompareMegacontent
 * @param {object} props - Props
 */
import React, { Fragment, useState } from "react";
import SwiperCore, { Controller, Pagination, A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from '../../components/product-card'
import Attributes from "../../components/product-card-attributes"
import Icon from "../../atoms/icon"
SwiperCore.use([Pagination, A11y, Navigation, Controller]);

const CompareMegacontent = (
	{
		products,
		title,
		icon,
		showAttributesLabels,
		productsLabel,
	}

) => {

	const defaultBreakpoints = {
		// 320: {
		// 	slidesPerView: 3,
		// 	spaceBetween: 10,
		// },
		// 768: {
		// 	spaceBetween: 30,
		// 	slidesPerView: 3,
		// 	spaceBetween: 30,
		// }
		// when window width is >= 320px
		375: {
			slidesPerView: 1,
			spaceBetween: 10,
		},
		// when window width is >= 480px
		480: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		// when window width is >= 640px
		640: {
			slidesPerView: 2,
			spaceBetween: 30
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 30
		}
	};


	const [firstSwiper, setFirstSwiper] = useState(null);
	const [secondSwiper, setSecondSwiper] = useState(null);

	//sample product
	const sp = products[0].node;
	const legend = [];

	{
		sp.attributes.map((attribute, idx) => (
			legend[idx] = attribute
		))
	}

	const [showMore, setShowMore] = useState('collapsed');

	const toggleDetails = () => {
		window.dispatchEvent(new Event('resize')); //needed --> unexpected behaviour of collapsed details
		setShowMore(showMore === 'collapsed' ? 'expanded' : 'collapsed')
	}


	return (


		<Fragment>
			{
				products ?
					<div class={`megacontent-compare megacontent-compare--${showMore}`}>
						<div className="megacontent-compare__navigation">
							<div className="holder">
								<div className="megacontent-compare__navigation-next"><span className="btn next"></span></div>
								<div className="megacontent-compare__navigation-prev"><span className="btn prev"></span></div>
							</div>
						</div>

						<div className="megacontent-compare__compare">
							<div className="megacontent-compare__head">
								{
									title ?
										<h2>
											{
												icon ?
													<Icon
														color="white"
														name={icon}
													/>
													: null
											}
											{title}
										</h2>
										: null
								}
								{
									productsLabel ?
										<div class="megacontent-compare__length">
											{products.length} {productsLabel}
										</div>
										: null

								}
								{
									showAttributesLabels ?
										<div class="megacontent-compare__show-details" labels onClick={() => toggleDetails()}>
											{showMore === 'collapsed' ? showAttributesLabels.show : showAttributesLabels.hide} <span className="icon"></span>
										</div>
										: null

								}
							</div>
							<div className="megacontent-compare__data">
								<div className="megacontent-compare__products">

									{
										products ?
											<div className="megacontent-compare__swiper">
												<Swiper
													onSwiper={setFirstSwiper}
													controller={{ control: secondSwiper }}
													breakpoints={defaultBreakpoints}
													// onSwiper={(swiper) => console.log(swiper)}
													// onSlideChange={() => console.log('slide change')}
													//pagination={{ clickable: true }}
													navigation={{
														nextEl: '.megacontent-compare__navigation-next .btn',
														prevEl: '.megacontent-compare__navigation-prev .btn',
													}}
													// navigation
													centeredSlides={false}
												>
													{
														products.map((p, idx) => (
															<SwiperSlide key={idx}>
																{/*<CardArticle {...item} />*/}
																<ProductCard
																	callToAction={{
																		label: 'Vai alla scheda prodotto',
																		link: '/pdp'
																	}}
																	kspsExpandLabel={{
																		expand: 'mostra altri', //espand key selling points panel
																		collapse: 'mostra meno' //collapse key selling points panel
																	}}
																	productNameVerbose={p.node.productNameVerbose}
																	{...p.node}
																	key={idx}
																	compare
																/>
															</SwiperSlide>
														))
													}
												</Swiper>
											</div>
											: null
									}
								</div>
								<div className="megacontent-compare__attributes">
									<ul className="megacontent-compare__attributes--legend">
										{
											legend.map((attribute, idx) => (
												<li key={idx}>
													<span>
														{attribute.fieldName}
													</span>
												</li>
											))
										}
									</ul>
									<div className="megacontent-compare__swiper">
										<Swiper
											onSwiper={setSecondSwiper}
											controller={{ control: firstSwiper }}
											breakpoints={defaultBreakpoints}
											// onSwiper={(swiper) => console.log(swiper)}
											// onSlideChange={() => console.log('slide change')}
											//pagination={{ clickable: true }}
											centeredSlides={false}
											pagination={{
												clickable: true,
												el: '.megacontent-compare__pagination .bullets',
												type: 'bullets',
											}}
										>
											{
												products.map((p, idx) => (
													<SwiperSlide key={idx}>
														<Attributes {...p.node} />
													</SwiperSlide>
												))
											}
										</Swiper>
									</div>
								</div>
							</div>


						</div>

						{
							showAttributesLabels ?
								<div class="megacontent-compare__show-details megacontent-compare__show-details--mobile" labels onClick={() => setShowMore(showMore === 'collapsed' ? 'expanded' : 'collapsed')}>
									{showMore === 'collapsed' ? showAttributesLabels.show : showAttributesLabels.hide} <span className="icon"></span>
								</div>
								: null

						}
						<div className="container">
							<div className="megacontent-compare__pagination">
								<div className="bullets"></div>
							</div>
						</div>

					</div>

					: null
			}

		</Fragment>



	);
}
export default CompareMegacontent;